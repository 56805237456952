export const MAPBOX_COUNTRY_CODE = 'NO';
export const MAPBOX_REGION_PLACE_NAME = 'Bergen';
export const MAPBOX_STYLE_URL = "mapbox://styles/teropa/ckbc1rriu0mcx1inu9wlkna38";

export const REGION_NAME_PROPERTY = 'name';

// Finnish mobile number in national or international format
// "Regional" codes based on https://www.traficom.fi/fi/viestinta/laajakaista-ja-puhelin/matkaviestinverkkojen-suuntanumerot
export const PHONE_NUMBER_REGEX = /^(0047|\+47)[2-9]\d{7}$/;

// A prefix to put in front of phone numbers to make a phone call and obscure your number. Used in the artist UI
// so they can call gift gives whilst preserving their privacy. If not supported in you country, make blank.
export const PHONE_NUMBER_PRIVACY_PREFIX = '#31#';

// To test whether a street address string contains the necessary street number
export const ADDRESS_STREET_NUMBER_MATCH = /\d/;

// To extract from a street address the part that is needed to geocode it with the Mapbox API
export const ADDRESS_GEOCODING_PREFIX = /.*?\d+/;

// The default selection for the gift receiver language
export const DEFAULT_TO_LANGUAGE = 'no';
